import React, {useState} from "react";
/// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
/// @material-ui/icons
import People from "@material-ui/icons/People";
/// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from 'react-router-dom';

import styles from "./syles";
import { post, useInput } from "../../utility";

const useStyles = makeStyles(styles);

export default function Login() {

    const resetError = (showError) => {
        return () => {
            if(showError) {
                setShowError(false);
            }
        }
    };

    const [ showError, setShowError ] = useState(false);
    const [ userId, setUserId ] = useInput("", resetError(showError));
    const [ password, setPassword ] = useInput("", resetError(showError));

    const classes = useStyles();

    const history = useHistory();

    const onSubmit = async () => {
        const body = JSON.stringify({
            username: userId,
            password
        });

        try {
            const response = await post('/auth', { body });

            if(response.ok) {
                const resJson = await response.json();
                const token = resJson.jwtToken;
                localStorage.setItem('customerPortalToken', token);
                history.push('/');
            } else {
                setShowError(true);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onResetClicked = () => {
        history.push('/reset-password');
    };

    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <form className={classes.form}>
                                <CardHeader color="primary" className={classes.cardHeader}>
                                    <h4>Customer Portal</h4>
                                    <h4>Login</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.errorContainer}>
                                        { showError ? 'Incorrect user ID or password, please try again' : ''}
                                    </div>
                                    <CustomInput
                                        labelText="User ID"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <People className={classes.inputIconsColor} />
                                                </InputAdornment>
                                            ),
                                            value: userId,
                                            onChange: setUserId
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className={classes.inputIconsColor}>
                                                        lock_outline
                                                    </Icon>
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                            value: password,
                                            onChange: setPassword
                                        }}
                                    />
                                </CardBody>
                                <CardFooter className={classes.cardFooter}>
                                    <Button simple color="primary" size="lg" onClick={onSubmit}>
                                        Login
                                    </Button>
                                    <Button simple color="primary" size="lg" onClick={onResetClicked}>
                                        Reset Password
                                    </Button>
                                </CardFooter>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
