import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import StoreIcon from '@material-ui/icons/Store';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import styles from "assets/jss/material-kit-react/views/trackingPage.js";

import { postWithToken } from '../../utility'
import Button from "../../components/CustomButtons/Button";

const useStyles = makeStyles(styles);

export default function TrackingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [tracking, setTracking] = useState([])

  useEffect(() => {
    // if the user goes straight to the tracking page
    // send them back to the orders page to click on an shCode and come back
    if(!props.shipDate || !props.stopNumber || !props.routeId) {
        props.history.push({
            pathname: '/'
        });
    }
    let options = {
      body: JSON.stringify({
        shipDate: props.shipDate,
        stopNumber: props.stopNumber,
        routeId: props.routeId
      })
    };
    postWithToken(`/tracking`, options)
    .then(res => res.json())
    .then(data => {
      setTracking(data)
    })
    .catch((error) => {
      // TODO: render error message on the page
      console.log('Error: ', error)
    })
  }, [
    props.history,
    props.routeId,
    props.shipDate,
    props.stopNumber,
  ])

  const styleMapping = {
    "Warehouse": { color: "primary", icon: <StoreIcon /> },
    "Truck": { color: "primary", icon: <LocalShippingIcon /> },
    "In Transit": { color: "primary", icon: <LocalShippingIcon /> },
    "Delayed": { color: "primary", icon: <LocalShippingIcon /> },
    "Delivered": { color: "primary", icon: <LocationOnIcon /> },
    "ETA": { color: "primary", icon: <LocationOnIcon /> },
  }

  const getAddress = (address) => {
    if(address) {
      return `${address.address1} ${address.address2}, ${address.city}, ${address.state}, ${address.zipcode}`;
    }
  };

  const getTimelineItem = (tracking, index) => (
    <TimelineItem key={index}>
      <TimelineSeparator>
        <TimelineDot color={ styleMapping[tracking.title]?.color } variant="outlined">
          { styleMapping[tracking.title]?.icon }
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={tracking.done ? classes.paperDone : classes.paperNotDone}>
          <Typography variant="h6" component="h1">
          { tracking.title }
          </Typography>
          <Typography>{ tracking.message }</Typography>
          <Typography variant="h6">{ tracking.liquorStoreName }</Typography>
          <Typography>{ getAddress(tracking.liquorStoreAddress) }</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )

  const backToOrdersPage = () => {
    props.setCurrentPage('ordersPage');
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="FDL Customer Portal"
        rightLinks={<HeaderLinks setCurrentPage={props.setCurrentPage} />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="start">
              <GridItem xs={12} sm={12} md={1} style={{marginTop: "15px"}}>
                <Button color="danger" onClick={backToOrdersPage}>Back to orders page</Button>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <Timeline align="alternate">
                  { tracking.map(getTimelineItem) }
                </Timeline>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
