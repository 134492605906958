import React, {useState} from "react";
/// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
/// @material-ui/icons
import People from "@material-ui/icons/People";
/// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from 'react-router-dom';

import styles from "./styles";
import { post, useInput } from "../../utility";

const useStyles = makeStyles(styles);

export default function ResetPassword() {

    const applySideEffect = (errorState) => {
        return () => {
            if(errorState) {
                setShowWrongUserNamePasswordError(false);
            }
            if(showSuccessBanner) {
                setShowSuccessBanner(false);
            }
        }
    };

    const [ showWrongUserNamePasswordError, setShowWrongUserNamePasswordError ] = useState(false);
    const [ showConfirmPasswordError, setShowConfirmPasswordError ] = useState(false);
    const [ showDBError, setShowDBError ] = useState(false);
    const [ showSuccessBanner, setShowSuccessBanner ] = useState(false);
    const [ userId, setUserId ] = useInput("", applySideEffect(showWrongUserNamePasswordError));
    const [ currentPassword, setCurrentPassword ] = useInput("", applySideEffect(showWrongUserNamePasswordError));
    const [ newPassword, setNewPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");

    const classes = useStyles();

    const history = useHistory();

    const onNewPasswordInputChange = (e) => {
        setNewPassword(e.target.value);
        /// only if the confirm password field is not blank, we verify if they are the same
        if(confirmPassword && confirmPassword !== e.target.value) {
            setShowConfirmPasswordError(true);
        } else {
            setShowConfirmPasswordError(false);
        }
        setShowSuccessBanner(false);
    };

    const onConfirmPasswordInputChange = (e) => {
        setConfirmPassword(e.target.value);
        if(newPassword !== e.target.value) {
            setShowConfirmPasswordError(true);
        } else {
            setShowConfirmPasswordError(false);
        }
        setShowSuccessBanner(false);
    };

    const onSubmit = async () => {
        setShowSuccessBanner(false);
        const body = JSON.stringify({
            username: userId,
            currentPassword,
            newPassword
        });
        try {
            const response = await post('/resetPassword', { body });

            const resJson = await response.json();
            if(response.ok && resJson.isSuccessful) {
                setShowSuccessBanner(true);
            } else if(response.ok && !resJson.isSuccessful) {
                setShowWrongUserNamePasswordError(true);
            } else {
                setShowDBError(true);
            }
        } catch (e) {
            setShowDBError(true);
            console.log(e);
        }
    };

    const onGoBackClicked = () => {
        history.push('/');
    };

    const submitButtonDisabled =
        showWrongUserNamePasswordError ||
        showWrongUserNamePasswordError ||
        !userId ||
        !currentPassword ||
        !newPassword ||
        !confirmPassword

    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <form className={classes.form}>
                                <CardHeader color="primary" className={classes.cardHeader}>
                                    <h4>Reset Password</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.errorContainer}>
                                        <div>
                                            { showConfirmPasswordError ? 'New password and confirm password has to be non empty and the same' : ''}
                                        </div>
                                        <div>
                                            { showWrongUserNamePasswordError ? 'Incorrect user ID or password, please try again' : '' }
                                        </div>
                                        <div>
                                            { showDBError ? "Oops, something went wrong, please try again later" : "" }
                                        </div>
                                        <div style={{color: "green"}}>
                                            { showSuccessBanner ? "Password reset successfully, now you can go back to login page" : "" }
                                        </div>
                                    </div>
                                    <CustomInput
                                        labelText="User ID"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <People className={classes.inputIconsColor} />
                                                </InputAdornment>
                                            ),
                                            value: userId,
                                            onChange: setUserId
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Current password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className={classes.inputIconsColor}>
                                                        lock_outline
                                                    </Icon>
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                            value: currentPassword,
                                            onChange: setCurrentPassword
                                        }}
                                    />
                                    <CustomInput
                                        labelText="New password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className={classes.inputIconsColor}>
                                                        lock_outline
                                                    </Icon>
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                            value: newPassword,
                                            onChange: onNewPasswordInputChange
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Confirm new password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className={classes.inputIconsColor}>
                                                        lock_outline
                                                    </Icon>
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                            value: confirmPassword,
                                            onChange: onConfirmPasswordInputChange
                                        }}
                                    />
                                </CardBody>
                                <CardFooter className={classes.cardFooter}>
                                    <Button simple color="primary" size="lg" disabled={submitButtonDisabled} onClick={onSubmit}>
                                        Submit
                                    </Button>
                                    <Button simple color="primary" size="lg" onClick={onGoBackClicked}>
                                        Go Back
                                    </Button>
                                </CardFooter>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
