import React, {useState} from "react";
import OrdersPage from "./OrdersPage/OrdersPage";
import TrackingPage from "./TrackingPage/TrackingPage";

export default function(props) {
  const [ currentPage, setCurrentPage ] = useState('ordersPage');
  const [ shipDate, setShipDate ] = useState();
  const [ routeId, setRouteId ] = useState();
  const [ stopNumber, setStopNumber ] = useState();
  const [invoice, setInvoice] = useState()

  if(currentPage === 'ordersPage') {
    return (
      <OrdersPage
        {...props}
        setCurrentPage={setCurrentPage}
        setRouteId={setRouteId}
        setStopNumber={setStopNumber}
        setShipDate={setShipDate}
        shipDate={shipDate}
        invoice={invoice}
        setInvoice={setInvoice}
      />
    )
  } else {
    return (
      <TrackingPage
        {...props}
        shipDate={shipDate}
        routeId={routeId}
        stopNumber={stopNumber}
        setCurrentPage={setCurrentPage}
      />
    )
  }
}
