import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react plugin for creating date-time-picker
import Datetime from "react-datetime";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from '@material-ui/core/Tooltip';

import CircularProgress from '@material-ui/core/CircularProgress';

import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";

import { GoogleMap, LoadScript, MarkerClusterer, Marker } from '@react-google-maps/api'
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableCharIcon from "@material-ui/icons/TableChart";
import MapIcon from "@material-ui/icons/Map";

import { CSVDownload } from 'react-csv'

import styles from "assets/jss/material-kit-react/views/ordersPage.js";

import moment from 'moment'
import { getWithToken } from '../../utility'

import _ from "lodash";

import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(styles);


export default function OrdersPage(props) {
  const classes = useStyles();
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);

  useEffect(() => {
    if (!downloadCsv) return

    setDownloadCsv(false)
  }, [downloadCsv])

  const { orders, setOrders, setCurrentPage, shipDate, setShipDate, setStopNumber, setRouteId, invoice, setInvoice } = props;
  const initSortStatus = {
    invoice: false,
    liquorStoreCity: false,
    liquorStoreState: false,
    status: false
  };
  const [ sortStatus, setSortStatus ] = useState(initSortStatus);
  const [ isAlertVisible, setIsAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState("oops! Something went wrong, please try again");

  const findOrders = () => {
    setIsLoadingOrder(true);
    getWithToken(`/orders?shipdate=${moment(shipDate).format('YYYY-MM-DD')}&invoice=${invoice || ''}`)
    .then(res => res.json())
    .then(data => {
      setOrders(data);
      setIsLoadingOrder(false);
    })
    .catch((error) => {
      // TODO: render error message on the page
      console.log('Error: ', error)
    })
  }

  const onShCodeClicked = (shipDate, stopNumber, routeId) => {
    return (() => {
      setCurrentPage('trackingPage');
      setShipDate(shipDate);
      setStopNumber(stopNumber);
      setRouteId(routeId);
    });
  };

  const onCsvDownload = () => {
    setDownloadCsv(true)
  }

  const getDownloadButton = () => {
    if(orders && orders.length) {
      return (
        <GridItem xs={12} sm={12} md={1}>
          <Button onClick={onCsvDownload} color="primary">Download as CSV</Button>
          { downloadCsv && <CSVDownload data={orders} target="_blank" /> }
        </GridItem>
      );
    }
  };

  const getStatusTextAndColor = (status) => {
    let result;
    switch (status) {
      case '0':
      case '1':
        result = {status: 'In Transit', color: 'blue'};
        break;
      case '2':
        result = {status: 'Delivered', color: 'green'};
        break;
      case '3':
        result = {status: 'Cancelled', color: 'red'};
        break;
      default:
        result = {status: 'In Transit', color: 'blue'};
    }
    return result;
  };

  const sortOnField = (fieldName) => {
    return (() => {
      let newOrders = [];
      if(sortStatus[fieldName]) {
        newOrders = _.reverse(orders);
      } else {
        newOrders = _.sortBy(orders, [fieldName])
      }
      let newSortStatus = Object.assign({}, initSortStatus);
      newSortStatus[fieldName] = true;
      setOrders(newOrders);
      setSortStatus(newSortStatus);
    });
  };

  const CommentCell = ({row}) => {
    const Ptdesc = () => {
      if(row.ptdesc) {
        return (
          <div> {row.ptdesc} </div>
        );
      }
      return null;
    };

    const Notes = () => {
      if(row.comments) {
        return (
          <Tooltip title={row.comments}>
            <a style={{cursor: "pointer", textDecoration: "underline"}}>Notes</a>
          </Tooltip>
        );
      }
      return null;
    };
    return (
      <div>
        <Ptdesc />
        <Notes />
      </div>
    );
  };

  const getAlert = () => {
    if(isAlertVisible) {
      return (
        <div style={{position: 'absolute', right: '100px', top: '120px'}}>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            { alertContent }
          </Alert>
        </div>
      );
    }
  };

  const getSignatureCell = (row) => {

    const onClick = async () => {
      try {
        let rawResponse = await getWithToken(`/orders/${moment(shipDate).format('YYYY-MM-DD')}/${row.shcode}/bol`);
        let response = await rawResponse.json();
        if(response.bolPath) {
          window.open(response.bolPath, '_blank');
        } else {
          setTimeout(() => {
            setIsAlertVisible(false);
            setAlertContent("oops! Something went wrong, please try again");
          }, 5000)
          setIsAlertVisible(true);
          setAlertContent(`There's no signature yet for this order, please try again later`);
        }
      } catch (e) {
        console.log("Error!" + e);
        setTimeout(() => {
          setIsAlertVisible(false);
        }, 5000)
        setIsAlertVisible(true);
        setAlertContent("oops! Something went wrong, please try again");
      }
    }

    return (
      row.bolPath ? (
        <Tooltip title={`Click to open in a new window`}>
          <a onClick={onClick} style={{ cursor: "pointer", textDecoration: "underline" }}>View</a>
        </Tooltip>
      ) : (<div />)
    );
  }

  const ordersTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{cursor: 'pointer'}} onClick={sortOnField('invoice')} align="right">Invoice</TableCell>
            <TableCell style={{cursor: 'pointer'}} onClick={sortOnField('routeId')} align="right">RouteId</TableCell>
            <TableCell align="right">StoreName</TableCell>
            <TableCell style={{cursor: 'pointer'}} onClick={sortOnField('liquorStoreCity')} align="right">City</TableCell>
            <TableCell style={{cursor: 'pointer'}} onClick={sortOnField('liquorStoreState')} align="right">State</TableCell>
            <TableCell style={{cursor: 'pointer'}} onClick={sortOnField('status')} align="right">Status</TableCell>
            <TableCell align="right">Comments</TableCell>
            <TableCell align="right">Signature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length ? orders.map((row) => (
              <TableRow key={row._id}>
                <TableCell align="right">
                  <div className={classes.invoiceContainer}
                        onClick={onShCodeClicked(row.shipdate, row.stopNumber, row.routeId)}>
                    {row.invoice}
                  </div>
                </TableCell>
                <TableCell align="right">{row.routeId}</TableCell>
                <TableCell align="right">{row.liquorStoreName}</TableCell>
                <TableCell align="right">{row.liquorStoreCity}</TableCell>
                <TableCell align="right">{row.liquorStoreState}</TableCell>
                <TableCell align="right" style={{color: `${getStatusTextAndColor(row.status).color}`}}>
                  {getStatusTextAndColor(row.status).status}
                </TableCell>
                <TableCell align="right">
                  <CommentCell row={row} />
                </TableCell>
                <TableCell align="right">
                  { getSignatureCell(row) }
                </TableCell>
              </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )

  const ordersMap = () => (
    <GridItem xs={12} sm={12} md={12} className={classes.mapWrapper}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={{ lat: 40.0583, lng: -74.4057 }}
          zoom={10}
          onLoad={map => {
            const latlngs = orders.map(order => new window.google.maps.LatLng(order.lat, order.lng))
            const latlngbounds = new window.google.maps.LatLngBounds();
            latlngs.forEach(latlng => latlngbounds.extend(latlng))
            map.fitBounds(latlngbounds)
          }}
        >
          <MarkerClusterer options={
            { imagePath: `${process.env.REACT_APP_SERVER_URL}/markerclusterer/m` }
          }>
            {
              (clusterer) => orders.map(({ lat, lng }, i) => (
                <Marker
                  key={i}
                  position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                  icon={`${process.env.REACT_APP_SERVER_URL}/bottle-10-32.ico`}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </GridItem>
  )

  return (
    <div>
      <Header
        color="transparent"
        brand="FDL Customer Portal"
        rightLinks={<HeaderLinks setCurrentPage={setCurrentPage} />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{maxWidth: "1600px"}}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <Datetime
                    inputProps={{ placeholder: "Pick a shipdate" }}
                    onChange={setShipDate}
                    value={shipDate}
                    timeFormat={false}
                    closeOnSelect={true}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  id="invoice"
                  inputProps={{
                    placeholder: "Invoice #",
                    value: invoice,
                    onChange: (e) => { e.preventDefault(); setInvoice(e.target.value) },
                  }}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControl,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={1} style={{marginRight: "60px"}}>
                <Button onClick={findOrders} disabled={isLoadingOrder} color="primary">Find Orders</Button>
              </GridItem>
              { getDownloadButton() }
              { isLoadingOrder &&
                <GridItem xs={12} sm={12} md={1} style={{marginRight: "60px"}}>
                  <CircularProgress />
                </GridItem>
              }
              <GridItem xs={12} sm={12} md={10} className={classes.navWrapper}>
                <CustomTabs
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "Table",
                      tabIcon: TableCharIcon,
                      tabContent: ordersTable(),
                    },
                    {
                      tabName: "Map",
                      tabIcon: MapIcon,
                      tabContent: ordersMap(),
                    }
                  ]}
                />


              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      { getAlert() }
      <Footer />
    </div>
  );
}
