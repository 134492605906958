import React, {useState} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import GuardedRoute from 'components/GuardedRoute/GuardedRoute'

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import Login from "./components/Login/Login";
import Components from "./views/Components/Components";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import TrackingPage from "views/TrackingPage/TrackingPage"
import OrderAndTrackingPageContainer from "./views/Container";
import { useEffect } from 'react';

var hist = createBrowserHistory();

function App() {
  const [ orders, setOrders ] = useState([]);
  useEffect(() => {
    const script = document.createElement('script');
  //<script type="text/javascript" nonce="{place_your_nonce_value_here}" src="" defer></script>
    script.src = "https://desk.zoho.com/portal/api/web/inapp/596254000008798021?orgId=747594798";
    script.nonce = "{place_your_nonce_value_here}"
    script.defer = true
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <Router history={hist}>
      <Switch>
        {/* dev components */}
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/components" component={Components} />
        {/* prod components */}
        <Route path="/reset-password" component={ResetPassword} />
        <GuardedRoute path="/orders" component={OrderAndTrackingPageContainer} orders={orders} setOrders={setOrders} />
        <Route path="/login" component={Login} />
        <GuardedRoute path="/" component={OrderAndTrackingPageContainer} orders={orders} setOrders={setOrders} />
      </Switch>
    </Router>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
