/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const logout = () => {
      localStorage.removeItem('customerPortalToken');
  };
  const goBackToOrdersPage = () => {
     props.setCurrentPage('ordersPage');
  };
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          onClick={goBackToOrdersPage}
          color="transparent"
          className={classes.navLink}
        >
            Orders Page
        </Button>
        <Button
            href="/login"
            color="transparent"
            className={classes.navLink}
            onClick={logout}
        >
            Log out
        </Button>
      </ListItem>
    </List>
  );
}
