import { useState } from "react";

const HTTP_POST_METHOD = 'POST'
const HTTP_GET_METHOD = 'GET'

/// initiate a piece specifically for input field, returns the state and the setter to grab the value in the input field and setState
/// Also apply any side effects passed in if it's passed in by the caller
const useInput = (initValue, applySideEffects) => {
    const [ value, setValue ] = useState(initValue);
    const setInput = (e) => {
        setValue(e.target.value);
        if(applySideEffects) {
            applySideEffects();
        }
    };

    return [ value, setInput ];
};

const serverUrl = process.env.REACT_APP_SERVER_URL
const getCmpToken = () => localStorage.getItem('customerPortalToken')
const getUrl = (path) => `${serverUrl}${path}`
const httpRequest = (path, options = {}) => fetch(getUrl(path), { ...options, headers: { ...options.headers, 'content-type': 'application/json' } })
const get = (path, options = {}) => httpRequest(path, { ...options, method: HTTP_GET_METHOD })
const post = (path, options = {}) => httpRequest(path, { ...options, method: HTTP_POST_METHOD })
const httpRequestWithToken = (path, options = {}) => httpRequest(path, { ...options, headers: { ...options.headers, 'Authorization': `Bearer ${getCmpToken()}` } })
const getWithToken = (path, options = {}) => httpRequestWithToken(path, { ...options, method: HTTP_GET_METHOD })
const postWithToken = (path, options = {}) => httpRequestWithToken(path, { ...options, method: HTTP_POST_METHOD })


export {
    useInput,
    getWithToken,
    postWithToken,
    get,
    post,
};
