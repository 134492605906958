import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { postWithToken } from 'utility'

const GuardedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    postWithToken('/verifyToken')
    .then((res) => {
      setAuth(res.status != 401)
    })
    .catch((error) => {
      setAuth(false)

      // TODO: let user know what's wrong
      console.log(error)
    }).finally(() => setLoading(false))
  }, [])

  const renderComponent = (props) => {
    if (loading) {
      return <div>Loading...</div>
    }

    if (auth) {
      return <Component {...props} {...rest} />
    }

    return <Redirect to='/login' />
  }

  return <Route {...rest} render={renderComponent} />
}

export default GuardedRoute
